<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer">
                <b-form id="user-form">
                    <b-row class="mb-4">
                        <b-col cols="12" md="4">
                            <b-form-group label="Tipo">
                                <b-form-radio-group v-model="payment.type" :options="paymentTypes" @input="setEntityType"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group :label="payment.type === 'INCOME' ? 'Cliente' : 'Proveedor'" :invalid-feedback="payment.type === 'INCOME' ? 'Selecciona el cliente' : 'Selecciona el proveedor'" :state="errors[0] ? false : null">
                                    <v-select :options="businessEntities" @search:focus="getBusinessEntity" @search="getBusinessEntity"
                                              v-model="businessEntityQuery" @input="selectBusinessEntity" label="legalName" :clearable="false"
                                              :placeholder="payment.type === 'INCOME' ? 'Cliente' : 'Proveedor'">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="m-2 well p-4" style="font-size: small">
                        <template v-if="payment.businessEntity.type === 'FISICA'">
                            <b-col cols="12" md="3">
                                <label>Nombre</label>
                                <p>{{ payment.businessEntity.name ? payment.businessEntity.name : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>Apellido paterno</label>
                                <p>{{ payment.businessEntity.firstLastName ? payment.businessEntity.firstLastName : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>Apellido materno</label>
                                <p>{{ payment.businessEntity.secondLastName ? payment.businessEntity.secondLastName : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>RFC</label>
                                <p>{{ payment.businessEntity.rfc ? payment.businessEntity.rfc : 'Sin registro' }}</p>
                            </b-col>
                        </template>
                        <template v-else>
                            <b-col cols="12" md="3">
                                <label>Razón social</label>
                                <p>{{ payment.businessEntity.legalName ? payment.businessEntity.legalName : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>Régimen capital</label>
                                <p>{{ payment.businessEntity.legalEntityType ? payment.businessEntity.legalEntityType : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>Nombre comercial</label>
                                <p>{{ payment.businessEntity.commercialName ? payment.businessEntity.commercialName : 'Sin registro' }}</p>
                            </b-col>
                            <b-col cols="12" md="3">
                                <label>RFC</label>
                                <p>{{ payment.businessEntity.rfc ? payment.businessEntity.rfc : 'Sin registro' }}</p>
                            </b-col>
                        </template>
                        <b-col cols="12" md="3">
                            <label>Teléfono 1</label>
                            <p>{{ payment.businessEntity.phone1 ? payment.businessEntity.phone1 : 'Sin registro' }}</p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label>Teléfono 2</label>
                            <p>{{ payment.businessEntity.phone2 ? payment.businessEntity.phone2 : 'Sin registro' }}</p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label>Email 1</label>
                            <p>{{ payment.businessEntity.email1 ? payment.businessEntity.email1 : 'Sin registro' }}</p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <label>Email 2</label>
                            <p>{{ payment.businessEntity.email2 ? payment.businessEntity.email2 : 'Sin registro' }}</p>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4" v-if="paymentQuery === ''">
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Método de pago" invalid-feedback="Selecciona el método de pago" :state="errors[0] ? false : null">
                                    <v-select :options="methodTypes" v-model="payment.method" placeholder="Método de pago" :disabled="paymentQuery !== ''"
                                              :reduce="method => method.value" label="text" :clearable="false">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Fecha de pago" invalid-feedback="Selecciona la fecha de pago">
                                    <b-form-datepicker v-model="payment.paymentDate" boundary="window" placeholder="Fecha de pago" v-bind="datePickerLabels" locale="es"
                                                       hide-header :disabled="paymentQuery !== ''" :state="errors[0] ? false : null"></b-form-datepicker>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required|double|min_value:0" v-slot="{ errors }">
                                <b-form-group label="Importe" invalid-feedback="Ingresa el importe">
                                    <b-form-input v-model="payment.amount" autocomplete="false" :placeholder="`Importe`" style="text-align: right" :disabled="paymentQuery !== ''"
                                                  @keyup="setBalance" :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <hr>
                    <h3 class="mb-4">Cuentas por {{ payment.type === 'INCOME' ? 'cobrar' : 'pagar' }}</h3>
                    <b-row class="mb-4" v-if="paymentsBalance.length > 0">
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Pagos con saldo" invalid-feedback="Selecciona un pago con saldo" :state="errors[0] ? false : null">
                                    <v-select :options="paymentsBalance" placeholder="Pagos con saldo" label="balance" v-model="paymentQuery"
                                              @input="selectPayment" :clearable="false">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="m-2 well p-4" style="font-size: small" v-if="paymentQuery !== ''">
                        <b-col cols="12" sm="6" md="3">
                            <label>Folio</label>
                            <p>{{ payment.folio ? payment.folio : 'Sin registro' }}</p>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <label>Método de pago</label>
                            <p>
                                <span v-if="payment.method === 'CREDIT_CARD'">Tarjeta de crédito</span>
                                <span v-if="payment.method === 'DEBIT_CARD'">Tarjeta de ébito</span>
                                <span v-if="payment.method === 'CASH'">Efectivo</span>
                                <span v-if="payment.method === 'CHECK'">Cheque</span>
                                <span v-if="payment.method === 'TRANSFER'">Transferencia</span>
                            </p>
                        </b-col>
                        <b-col cols="12" sm="6" md="6">
                            <label>Fecha de pago</label>
                            <p>{{ payment.paymentDate ? payment.paymentDate : 'Sin registro' }}</p>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <label>Importe</label>
                            <p>${{ formatCurrency(payment.amount) }}</p>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <label>Abonos</label>
                            <p>${{ formatCurrency(payment.paidAmount) }}</p>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <label>Saldo</label>
                            <p>${{ formatCurrency(payment.balance) }}</p>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <label>Estatus</label>
                            <p>
                                <b-badge variant="danger" v-if="payment.status === 'CANCELLED'">Cancelado</b-badge>
                                <b-badge variant="info" v-if="payment.status === 'TO_VERIFY'">Por verificar</b-badge>
                                <b-badge variant="primary" v-if="payment.status === 'VERIFIED'">Verificado</b-badge>
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4 button-sticky-right">
                        <b-col>
                            <h4>
                                <span class="text-primary">Importe</span> ${{ formatCurrency(payment.amount) }} -
                                <span class="text-primary">Abonos</span> ${{ formatCurrency(payment.paidAmount) }} =
                                <span class="text-primary">Saldo</span> ${{ formatCurrency(payment.balance) }}
                            </h4>
                        </b-col>
                        <b-col>
                            <b-button class="button-sticky-right" @click="autoPayment">Autopagar</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col>
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th colspan="9">Abonos a cuenta</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 2%"></b-th>
                                        <b-th class="text-center" style="width: 2%">#</b-th>
                                        <b-th>Folio</b-th>
                                        <b-th>Concepto</b-th>
                                        <b-th style="width: 8%" class="text-right">Importe</b-th>
                                        <b-th style="width: 8%" class="text-right">{{ payment.type === 'INCOME' ? 'Cobrado' : 'Pagado' }}</b-th>
                                        <b-th style="width: 8%" class="text-right">Abono</b-th>
                                        <b-th style="width: 8%" class="text-right">Saldo {{ payment.type === 'INCOME' ? 'CXC' : 'CXP' }}</b-th>
                                        <b-th class="text-center">Acciones</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <template v-for="(account, index) in payment.accounts">
                                        <b-tr :key="'account-' + account.id">
                                            <b-td>
                                                <b-form-checkbox v-model="account.selected"  @input="accountDepositChange(account)"></b-form-checkbox>
                                            </b-td>
                                            <b-td class="text-center">{{ index + 1 }}</b-td>
                                            <b-td><router-link :to="{ name: 'AccountRpShow', params: { id: account.id }}" target="_blank">{{ account.folio }}</router-link></b-td>
                                            <b-td>
                                                <router-link :to="{ name: 'BusinessOrderShow', params: { id: account.objectId }}"
                                                             target="_blank" v-if="account.objectType === 'BusinessOrder'">{{ account.orderFolio }}</router-link>
                                                <router-link :to="{ name: 'ServiceOrderShow', params: { id: account.objectId }}"
                                                             target="_blank" v-else-if="account.objectType === 'ServiceOrder'">{{ account.orderFolio }}</router-link>
                                                <router-link :to="{ name: 'ExpenseShow', params: { id: account.objectId }}"
                                                             target="_blank" v-else-if="account.objectType === 'Expense'">Gasto general {{ account.orderFolio }}</router-link>
                                            </b-td>
                                            <b-td class="text-right">$ {{ formatCurrency(account.amount) }}</b-td>
                                            <b-td class="text-right">$ {{ formatCurrency(account.paidReceiveAmount) }}</b-td>
                                            <b-td>
                                                <ValidationProvider rules="double|min_value:0" v-slot="{ errors }">
                                                    <b-form-input v-model="account.deposit" autocomplete="false" :placeholder="`Abono`" style="text-align: right"
                                                                  :state="errors[0] ? false : null" @keyup="accountDepositChange()"></b-form-input>
                                                </ValidationProvider>
                                            </b-td>
                                            <b-td class="text-right">$ {{ formatCurrency(account.balance) }}</b-td>
                                            <b-td class="text-center" style="width: 80px">
                                                <b-button-group>
                                                    <b-button size="sm" variant="outline-primary" @click="showRow(account)" title="Detalle">
                                                        <font-awesome-icon icon="angle-down"></font-awesome-icon>
                                                    </b-button>
                                                </b-button-group>
                                            </b-td>
                                        </b-tr>
                                        <b-tr :key="'account-business' + account.id" v-if="account.isShow">
                                            <b-td colspan="2" style="border-left: none;"></b-td>
                                            <b-td colspan="6">
                                                <b-row>
                                                    <b-col>
                                                        <b-table-simple>
                                                            <b-thead>
                                                                <b-tr>
                                                                    <b-th>Concepto</b-th>
                                                                    <b-th style="width: 15%" class="text-right">Cantidad</b-th>
                                                                    <b-th style="width: 15%" class="text-right">Costo Unitario</b-th>
                                                                    <b-th style="width: 15%" class="text-right">Subtotal</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <template v-if="account.objectType === 'BusinessOrder'">
                                                                    <b-tr v-for="businessItem in account.businessData"
                                                                          :key="'account-business-item-' + businessItem.businessOrderItemId">
                                                                        <b-td>
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <span class="font-weight-bold">{{ businessItem.entityType === 'TOUR' ? 'Tour' : 'Traslado' }}: </span> {{ businessItem.serviceName }} - {{ businessItem. folio }}<br>
                                                                                    <template v-if="businessItem.entityType === 'TOUR'"><span class="font-weight-bold">Duración: </span>{{ businessItem.duration }} Día(s)</template>
                                                                                </b-col>
                                                                            </b-row>
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <label>Notas</label>
                                                                                    <p>{{ businessItem.notes ? businessItem.notes : 'Sin registro' }}</p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <template v-if="businessItem.adults">
                                                                                        <span class="font-weight-bold">Adultos: </span>{{ businessItem.adults }}<br>
                                                                                    </template>
                                                                                    <template v-if="businessItem.children">
                                                                                        <span class="font-weight-bold">Niños: </span>{{ businessItem.children }}
                                                                                    </template>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <template v-if="businessItem.adults">
                                                                                        <span class="font-weight-bold">Adulto: </span>$ {{ formatCurrency(businessItem.adultFare) }}<br>
                                                                                    </template>
                                                                                    <template v-if="businessItem.children">
                                                                                        <span class="font-weight-bold">Niño: </span>$ {{ formatCurrency(businessItem.childFare) }}
                                                                                    </template>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <template v-if="businessItem.adults">
                                                                                        $ {{ formatCurrency(businessItem.adultFare * businessItem.adults) }}<br>
                                                                                    </template>
                                                                                    <template v-if="businessItem.children">
                                                                                        $ {{ formatCurrency(businessItem.childFare * businessItem.children) }}<br>
                                                                                    </template>
                                                                                    <span class="font-weight-bold">Subtotal: </span>$ {{ formatCurrency(businessItem.total) }}
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </template>
                                                                <template v-else>
                                                                    <b-tr v-for="expense in account.expenseList" :key="'account-expense-' + expense.id">
                                                                        <b-td>
                                                                            <b-row>
                                                                                <b-col md="6">
                                                                                    <span class="font-weight-bold">Nombre: </span> {{ expense.name }}<br>
                                                                                    <span class="font-weight-bold">Registrado por: </span> {{ expense.createdBy }}<br>
                                                                                    <span class="font-weight-bold">Fecha de registro: </span> {{ formatDateTime(expense.createdDate) }}
                                                                                </b-col>
                                                                            </b-row>
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <label>Observaciones</label>
                                                                                    <p>{{ expense.notes ? expense.notes : 'Sin registro' }}</p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <p>{{ expense.qty }}</p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <p>$ {{ formatCurrency(expense.unitPrice) }}</p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                        <b-td class="text-right">
                                                                            <b-row>
                                                                                <b-col md="12">
                                                                                    <p>$ {{ formatCurrency(expense.total) }}</p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </template>
                                                            </b-tbody>
                                                            <b-tfoot>
                                                                <b-tr>
                                                                    <b-th colspan="3" class="text-right">Total</b-th>
                                                                    <b-th class="text-right">$ {{ formatCurrency(account.amount) }}</b-th>
                                                                </b-tr>
                                                            </b-tfoot>
                                                        </b-table-simple>
                                                    </b-col>
                                                </b-row>
                                            </b-td>
                                        </b-tr>
                                    </template>
                                    <b-tr v-if="payment.accounts.length === 0">
                                        <b-td colspan="9" class="text-center font-weight-bold">Sin registros</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" class="mr-2" :to="{ name: 'PaymentIndex' }"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button @click="validateForm" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar Pagos</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, numeric, double, min_value } from 'vee-validate/dist/rules';
import * as constants from '@constants';
import { BusinessEntity } from '../../models/businessEntity';
import Utilities, { formatCurrency, formatDateTime } from '../../js/utilities';
import { datePickerLabels } from '../../i18n/datePickerI18n';
import { Expense } from '../../models/expense';
import { businessEntityTypeUrl, accountDataUrl, paymentUrl, paymentBalanceURL } from '../../js/routes';
const dayjs = require('dayjs');

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('email', {
    ...email,
    message: 'Ingresa un email válido'
});

extend('numeric', {
    ...numeric,
    message: 'Ingresa solo números'
});

extend('double', {
    ...double,
    message: 'Ingrese solo números y decimales'
});

extend('min_value', {
    ...min_value,
    message: 'Ingresa valores mayores que 0'
});

export default {
    data() {
        return {
            payment: {
                id: 0,
                type: 'EXPENSE',
                paymentDate: '',
                amount: '',
                paidAmount: 0,
                balance: 0,
                businessEntity: {},
                notes: '',
                method: '',
                reference: '',
                isApproved: false,
                status: '',
                accounts: []
            },
            paymentTypes: [
                { text: 'Ingreso', value: 'INCOME' },
                { text: 'Egreso', value: 'EXPENSE' }
            ],
            methodTypes: [
                { text: 'Tarjeta de crédito', value: 'CREDIT_CARD' },
                { text: 'Tarjeta de débito', value: 'DEBIT_CARD' },
                { text: 'Efectivo', value: 'CASH' },
                { text: 'Cheque', value: 'CHECK' },
                { text: 'Transferencia', value: 'TRANSFER' }
            ],
            swalTitle: 'Pagos',
            breadcrumb: {
                title: 'Pagos',
                path: [
                    {
                        name: 'PaymentIndex',
                        text: 'Pagos'
                    }
                ]
            },
            action: 'Nueva',
            isLoading: false,
            selectedPayment: false,
            datePickerLabels: datePickerLabels.es,
            businessEntityQuery: '',
            paymentQuery: '',
            businessEntities: [],
            accountsCache: [],
            paymentsBalance: []
        };
    },
    created() {
        this.payment.businessEntity = BusinessEntity.create();
        this.payment.businessEntity.type = 'SUPPLIER';

        if (this.$route.name.includes('Edit')) {
            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        formatDateTime,
        formatCurrency,
        setEntityType() {
            this.payment.businessEntity = BusinessEntity.create();
            this.businessEntityQuery = '';
            this.payment.amount = '';
            this.payment.paidAmount = 0;
            this.payment.balance = 0;
            this.payment.method = '';
            this.payment.paymentDate = '';
            this.payment.accounts = [];
            this.paymentsBalance = [];
            this.paymentQuery = '';

            if (this.payment.type === 'INCOME') {
                this.payment.businessEntity.type = 'CUSTOMER';
            } else {
                this.payment.businessEntity.type = 'SUPPLIER';
            }

            this.$refs.observer.reset();
        },
        setBalance() {
            let amount = Utilities.isPositiveNumber(this.payment.amount) ? this.payment.amount : 0;

            this.payment.balance = amount - this.payment.paidAmount;
        },
        selectBusinessEntity() {
            this.isLoading = true;

            this.payment.businessEntity.id = this.businessEntityQuery.id;
            this.payment.businessEntity.name = this.businessEntityQuery.name;
            this.payment.businessEntity.firstLastName = this.businessEntityQuery.firstLastName;
            this.payment.businessEntity.secondLastName = this.businessEntityQuery.secondLastName;
            this.payment.businessEntity.legalName = this.businessEntityQuery.legalName;
            this.payment.businessEntity.rfc = this.businessEntityQuery.rfc;
            this.payment.businessEntity.commercialName = this.businessEntityQuery.commercialName;
            this.payment.businessEntity.legalEntityType = this.businessEntityQuery.legalEntityType;
            this.payment.businessEntity.phone1 = this.businessEntityQuery.phone1;
            this.payment.businessEntity.phone2 = this.businessEntityQuery.phone2;
            this.payment.businessEntity.email1 = this.businessEntityQuery.email1;
            this.payment.businessEntity.email2 = this.businessEntityQuery.email2;
            this.payment.id = 0;
            this.payment.folio = '';
            this.payment.reference = '';
            this.payment.amount = '';
            this.payment.paidAmount = 0;
            this.payment.balance = 0;
            this.payment.method = '';
            this.payment.paymentDate = '';
            this.selectedPayment = false;

            Promise.all([this.getAccounts(), this.getPaymentsBalance()]).finally(() => {
                this.isLoading = false;
            });
        },
        selectPayment() {
            this.payment.id = this.paymentQuery.id;
            this.payment.paymentDate = this.paymentQuery.paymentDate;
            this.payment.method = this.paymentQuery.method;
            this.payment.amount = this.paymentQuery.amount;
            this.payment.paidAmount = this.paymentQuery.paidAmount;
            this.payment.balance = this.paymentQuery.balance;
            this.payment.folio = this.paymentQuery.folio;
            this.payment.reference = this.paymentQuery.reference;
            this.payment.status = this.paymentQuery.status;

            this.selectedPayment = true;
        },
        getBusinessEntity(search) {
            this.axios.get(businessEntityTypeUrl(), { params: {
                search,
                entityType: this.payment.businessEntity.type
            }}).then(response => {
                this.businessEntities = response.data;
            }).catch(() => {
                this.businessEntities = [];
            });
        },
        getPaymentsBalance() {
            return this.axios.get(paymentBalanceURL(), { params: {
                type: this.payment.type,
                businessEntityId: this.payment.businessEntity.id
            }}).then(response => {
                this.paymentQuery = '';
                this.paymentsBalance = response.data;
            }).catch(() => {
                this.paymentsBalance = [];
            });
        },
        getAccounts() {
            return this.axios.get(accountDataUrl(), { params: {
                entityId: this.payment.businessEntity.id,
                entityType: this.payment.businessEntity.type
            }}).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.payment.accounts = response.data.data;

                    this.accountsCache = [];
                    this.payment.accounts.forEach(item => {
                        if (this.payment.type === 'EXPENSE') {
                            item.expenseList = [];
                            item.expensesData.forEach(expenseListItemData => {
                                let expenseListItem = Expense.create();

                                expenseListItem.id = expenseListItemData.id;
                                expenseListItem.name = expenseListItemData.name;
                                expenseListItem.unitPrice = expenseListItemData.unitPrice;
                                expenseListItem.total = expenseListItemData.total;
                                expenseListItem.notes = expenseListItemData.notes;
                                expenseListItem.createdBy = expenseListItemData.createdBy;
                                expenseListItem.createdDate = expenseListItemData.createdDate;
                                expenseListItem.status = expenseListItemData.status;
                                expenseListItem.category = expenseListItemData.category;
                                expenseListItem.qty = expenseListItemData.qty;

                                if (expenseListItem.category !== 'UNIDAD' && expenseListItem.status !== 'DELETE') {
                                    item.expenseList.push(expenseListItem);
                                }
                            });

                            let vehicleExpense = item.expensesData.filter(object => object.category === 'UNIDAD' && object.amount > 0);
                            if (vehicleExpense.length > 0 && this.payment.entityType === 'SUPPLIER') {
                                item.expenseList = vehicleExpense;
                            }
                        }

                        this.accountsCache.push({ ...item });
                    });
                }
            });
        },
        showRow(account) {
            if (!account.isShow) {
                account.isShow = true;
            } else {
                account.isShow = false;
            }
        },
        accountDepositChange(accountSelect) {
            if (accountSelect) {
                if (accountSelect.selected && accountSelect.deposit <= 0) {
                    if (parseFloat(accountSelect.balance) <= parseFloat(this.payment.balance)) {
                        accountSelect.deposit = accountSelect.balance;
                    } else {
                        accountSelect.deposit = this.payment.balance;
                    }
                } else if (!accountSelect.selected) {
                    accountSelect.deposit = 0;
                }
            }

            let amount = Utilities.isPositiveNumber(this.payment.amount) ? this.payment.amount : 0;
            this.payment.paidAmount = 0;
            this.payment.balance = amount;

            this.payment.accounts.forEach(account => {
                let index = this.accountsCache.findIndex(object => object.id == account.id);
                let deposit = parseFloat(Utilities.isPositiveNumber(account.deposit) ? account.deposit : 0);

                account.balance = parseFloat(this.accountsCache[index].balance) - deposit;
                this.payment.paidAmount = parseFloat(this.payment.paidAmount) + deposit;
                this.payment.balance = parseFloat(this.payment.balance) - deposit;

                if (parseFloat(account.deposit) > 0) {
                    account.selected = true;
                } else {
                    account.selected = false;
                }
            });

            if (this.selectedPayment) {
                this.payment.paidAmount = parseFloat(this.payment.paidAmount) + parseFloat(this.paymentQuery.paidAmount);
                this.payment.balance = parseFloat(this.payment.balance) - parseFloat(this.paymentQuery.paidAmount);
            }
        },
        autoPayment() {
            this.payment.accounts.forEach(account => {
                if (this.payment.balance > 0 && account.balance > 0) {
                    let deposit = 0;
                    if (parseFloat(account.balance) <= parseFloat(this.payment.balance)) {
                        deposit = parseFloat(account.balance);
                    } else {
                        deposit = parseFloat(this.payment.balance);
                    }

                    account.balance = parseFloat(account.balance) - deposit;
                    account.deposit = parseFloat(account.deposit) + deposit;
                    this.payment.paidAmount = parseFloat(this.payment.paidAmount) + deposit;
                    this.payment.balance = parseFloat(this.payment.balance) - deposit;

                    if (parseFloat(account.deposit) > 0) {
                        account.selected = true;
                    } else {
                        account.selected = false;
                    }
                }
            });
        },
        validateForm() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    let validateAccounts = true;
                    let $message = '';

                    // if (this.payment.accounts.filter(account => account.deposit > 0).length < 1) {
                    //     validateAccounts = false;
                    //     $message = 'Se debe abonar dinero al menos a una cuenta';
                    // }

                    if (this.payment.balance < 0) {
                        validateAccounts = false;
                        $message = 'El saldo del pago no puede ser menor a 0';
                    }

                    if (this.payment.accounts.filter(account => account.balance < 0).length >= 1) {
                        validateAccounts = false;
                        $message = 'El saldo de una cuenta no puede ser menor a 0';
                    }

                    if (validateAccounts) {
                        this.savePayment();
                    } else {
                        this.$bvToast.toast($message, {
                            variant: 'danger'
                        });
                    }
                }
            });
        },
        savePayment() {
            let title = '¿Deseas guardar los pagos?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    let payments = [];
                    payments.push(this.payment);

                    this.axios.post(paymentUrl(), payments).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            let title = '¿Qué deseas hacer?';

                            this.$swal({
                                title: title,
                                icon: 'question',
                                confirmButtonText: 'Ir al detalle del pago',
                                cancelButtonText: 'Ingresar un nuevo pago'
                            }).then(result => {
                                if (result.isConfirmed) {
                                    this.$root.$bvToast.toast(response.data.message, {
                                        title: this.swalTitle,
                                        variant: 'success'
                                    });

                                    this.$router.push({ name: 'PaymentShow', params: { id: response.data.id }});
                                } else {
                                    this.$root.$bvToast.toast(response.data.message, {
                                        title: this.swalTitle,
                                        variant: 'success'
                                    });

                                    this.payment.businessEntity.type = 'SUPPLIER';
                                    this.setEntityType();
                                }
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        }
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: transform 0.5s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(-120%);
}

.fade-enter-to,
.fade-leave {
    transform: translateX(0);
}

.custom-control-label {
    color: #ffffff !important;
}
</style>